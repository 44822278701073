import { Box, Flex } from "@chakra-ui/react";
import { Player } from "@lottiefiles/react-lottie-player";
import React from "react";
import loading from "../assets/Animation - 1696923495328.json";

const Loading = () => {
  return (
    <Flex
      w={{ base: "65%", md: "30%" }}
      h="100vh"
      m="auto"
      justifyContent="center"
      alignItems="center"
    >
      <Box>
        <Player autoplay loop src={loading}></Player>
      </Box>
    </Flex>
  );
};

export default Loading;
