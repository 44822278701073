import {
  Box,
  Flex,
  HStack,
  Text,
  IconButton,
  useDisclosure,
  Stack,
  Image,
  Menu,
  MenuButton,
  MenuList,
  useClipboard,
  Tooltip,
  Link,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import logo from "../assets/cow.png";

export default function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { hasCopied, onCopy } = useClipboard("maniacmilkstore@gmail.com");

  return (
    <>
      <Box
        bg="gray.50"
        px={{ base: "5", md: "20" }}
        position="sticky"
        zIndex="999"
        top="0"
      >
        <Flex
          h={16}
          alignItems={"center"}
          justifyContent={"space-between"}
          flexDirection={{ base: "row-reverse", md: "row" }}
        >
          <IconButton
            variant="ghost"
            _hover={{ variant: "ghost" }}
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          <a href="#hero">
            <Flex alignItems="center" gap="2">
              <Image w="8" src={logo} alt="logo" loading="lazy" />
              <Text
                fontSize={{ base: "22", md: "28px" }}
                fontFamily="Handlee"
                fontWeight="600"
                mt="2"
                color="#2c3b69"
              >
                maniac milk
              </Text>
            </Flex>
          </a>
          <HStack as={"nav"} spacing={6} display={{ base: "none", md: "flex" }}>
            <Text fontWeight="500">
              <a href="#hero">Home</a>
            </Text>
            <Text fontWeight="500">
              <a href="#products">Products</a>
            </Text>
            <Menu>
              <MenuButton fontWeight="500">Contact</MenuButton>
              <MenuList p="4">
                <Text>
                  <Tooltip
                    fontSize="15px"
                    label={hasCopied ? "Email Copied!" : "Copy Email"}
                    closeOnClick={false}
                    hasArrow
                  >
                    <Link color="#407bff" onClick={onCopy}>
                      maniacmilkstore@gmail.com
                    </Link>
                  </Tooltip>
                </Text>
                <a href="tel:+919689596925">
                  <Text mt="1" fontSize="15px">
                    +91 9689596925
                  </Text>
                </a>
              </MenuList>
            </Menu>
          </HStack>
        </Flex>

        {isOpen ? (
          <Box pb={8} pl="4" pt="5" display={{ md: "none" }}>
            <Stack as={"nav"} spacing={5}>
              <Text fontWeight="500">
                <a href="#hero">Home</a>
              </Text>
              <Text fontWeight="500">
                <a href="#products">Products</a>
              </Text>
              <Box>
                <Text fontWeight="500">Contact Us</Text>
                <Text fontSize="15px" mt="1">
                  <Tooltip
                    label={hasCopied ? "Email Copied!" : "Copy Email"}
                    closeOnClick={false}
                    hasArrow
                  >
                    <Link color="#407bff" onClick={onCopy}>
                      maniacmilkstore@gmail.com
                    </Link>
                  </Tooltip>
                </Text>
                <a href="tel:+919689596925">
                  <Text fontSize="14px" mt="2">
                    <Tooltip
                      fontSize="14px"
                      label="Call Now"
                      closeOnClick={false}
                      hasArrow
                    >
                      +91 9689596925
                    </Tooltip>
                  </Text>
                </a>
              </Box>
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
}
