import { Box, Flex, Link, Text, Tooltip, useClipboard } from "@chakra-ui/react";

export default function Footer() {
  const { hasCopied, onCopy } = useClipboard("maniacmilkstore@gmail.com");
  const currentYear = new Date().getFullYear();

  return (
    <>
      <Box bg="gray.50" w="100%" m="auto">
        <Flex
          w="100%"
          gap={{ base: "10", md: "0" }}
          justifyContent="space-around"
          alignItems="center"
          flexDirection={{ base: "column", md: "row" }}
          p="8"
        >
          <Box textAlign={{ base: "center", md: "left" }}>
            <Text mt="2" fontWeight="600">
              Contact Us
            </Text>
            <Text mt="2">
              <Tooltip
                fontSize="14px"
                label={hasCopied ? "Email Copied!" : "Copy Email"}
                closeOnClick={false}
                hasArrow
              >
                <Link color="#407bff" onClick={onCopy}>
                  maniacmilkstore@gmail.com
                </Link>
              </Tooltip>
            </Text>
            <Tooltip
              fontSize="14px"
              label="Call Now"
              closeOnClick={false}
              hasArrow
            >
              <a href="tel:+919689596925">
                <Text fontSize="15px">+91 9689596925</Text>
              </a>
            </Tooltip>
          </Box>

          <Box>
            <Text fontWeight="600" textAlign={{ base: "center", md: "left" }}>
              Follow Us
            </Text>
            <Flex gap="4" fontSize="20px" mt="3">
              <a
                href="https://www.instagram.com/maniacmilkstore/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
                target="blank"
                rel="noopener"
              >
                <i className="fa-brands fa-square-instagram"></i>
              </a>
              <a
                href="https://www.instagram.com/maniacmilkstore/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
                target="blank"
                rel="noopener"
              >
                <i className="fa-brands fa-facebook"></i>
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=9689596925&text=Welcome to maniac milk"
                target="blank"
                rel="noopener"
              >
                <i className="fa-brands fa-whatsapp"></i>
              </a>
            </Flex>
          </Box>
        </Flex>
      </Box>

      <Box textAlign="center" p="5" bg="gray.50" borderTop="1px solid #cecece">
        <Text fontSize={"sm"}>
          © {currentYear} Maniac milk. All rights reserved
        </Text>
      </Box>
    </>
  );
}
