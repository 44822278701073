import { useEffect, useState } from "react";
import "./App.css";
import Benefits from "./components/Benefits";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Loading from "./components/Loading";
import Navbar from "./components/Navbar";
import Products from "./components/Products";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="App">
      <Navbar />
      <Hero />
      <Products />
      <Benefits />
      <Footer />
    </div>
  );
}

export default App;
