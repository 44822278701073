import { Box, Image, Text, Button, Flex } from "@chakra-ui/react";
import hero from "../assets/cattle-field.jpg";
import React from "react";

const Hero = () => {
  return (
    <Box id="hero" w="100%" position="relative">
      <Image
        w="100%"
        h={{ base: "70vh", md: "100vh" }}
        src={hero}
        objectFit="cover"
        alt="hero"
        loading="lazy"
      />

      <Box
        position="absolute"
        top={{ base: "12%", md: "20%" }}
        left={{ base: "8", md: "20" }}
        textAlign="left"
      >
        <Flex alignItems="center" gap="3">
          <Box color="#0135a6" fontSize="14px">
            #dairy
          </Box>
          <Box color="#0135a6" fontSize="14px">
            #fresh
          </Box>
          <Box color="#0135a6" fontSize="14px">
            #organic
          </Box>
        </Flex>
        <Text
          fontSize={{ base: "35px", md: "55px" }}
          fontWeight="600"
          w={{ base: "95%", md: "100%", lg: "100%" }}
          color="#28282d"
          mt="2"
          lineHeight={{ base: "38px", md: "65px" }}
        >
          Get Organic & Fresh
          <br />
          Dairy Products
        </Text>

        <a href="#products">
          <Button
            bg="#0135a6"
            _hover={{ bg: "#2c3b69" }}
            px="10"
            mt="6"
            fontSize="16px"
            size="lg"
            color="#f2f2f2"
            fontWeight="normal"
          >
            Explore
          </Button>
        </a>
      </Box>
    </Box>
  );
};

export default Hero;
