import { Box, Image, Text, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const Products = () => {
  useEffect(() => {
    Aos.init({ duration: 900 });
  }, []);

  return (
    <Box
      id="products"
      pt="20"
      w="85%"
      m="auto"
      mt={{ base: "", md: "16" }}
      textAlign="center"
    >
      <Text
        fontSize={{ base: "25px", md: "30px" }}
        fontWeight="600"
        color="#28282d"
      >
        Our Products
      </Text>
      <Text>Quality is Our First Priority</Text>

      <SimpleGrid
        columns={[1, 2, 3, 4]}
        rowGap={{ base: "40px", md: "60px" }}
        columnGap="40px"
        mt="10"
      >
        <Box
          data-aos="zoom-in-up"
          borderRadius="10"
          boxShadow="rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"
          _hover={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
        >
          <Image
            h={{ base: "45vh", md: "30vh", lg: "30vh" }}
            w="100%"
            objectFit="cover"
            src="https://media.istockphoto.com/id/1214850940/photo/yogurt-is-good-for-health-with-black-background.jpg?s=612x612&w=0&k=20&c=8GqPjqx9ykwamtCXQE_lOfsQRTQE89RxzBz2kcndXEg="
            alt="dahi"
            loading="lazy"
            borderTopRadius="10"
          />

          <Text
            m="4"
            fontWeight="bold"
            fontSize="18px"
            color="#2c3b69"
            fontFamily="Handlee"
          >
            Dahi
          </Text>
        </Box>
        <Box
          data-aos="zoom-in-up"
          borderRadius="10"
          boxShadow="rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"
          _hover={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
        >
          <Image
            h={{ base: "45vh", md: "30vh", lg: "30vh" }}
            w="100%"
            objectFit="cover"
            src="https://miro.medium.com/v2/resize:fit:512/1*MIIDHmrs21TsvUUeR1NzkQ.png"
            alt="ghee"
            loading="lazy"
            borderTopRadius="10"
          />

          <Text
            m="4"
            fontWeight="bold"
            fontSize="18px"
            color="#2c3b69"
            fontFamily="Handlee"
          >
            Ghee
          </Text>
        </Box>
        <Box
          data-aos="zoom-in-up"
          borderRadius="10"
          boxShadow="rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"
          _hover={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
        >
          <Image
            h={{ base: "45vh", md: "30vh", lg: "30vh" }}
            w="100%"
            objectFit="cover"
            src="https://media.istockphoto.com/id/906372070/photo/butter.jpg?s=612x612&w=0&k=20&c=cBD5fIc1AdiDdqvE2RBBz_sBhR9n38unk2Q1rxj1rQM="
            alt="butter"
            loading="lazy"
            borderTopRadius="10"
          />

          <Text
            m="4"
            fontWeight="bold"
            fontSize="18px"
            color="#2c3b69"
            fontFamily="Handlee"
          >
            Butter
          </Text>
        </Box>
        <Box
          data-aos="zoom-in-up"
          borderRadius="10"
          boxShadow="rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"
          _hover={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
        >
          <Image
            h={{ base: "45vh", md: "30vh", lg: "30vh" }}
            w="100%"
            objectFit="cover"
            src="https://wallpaperaccess.com/full/3927570.jpg"
            alt="paneer"
            loading="lazy"
            borderTopRadius="10"
          />

          <Text
            m="4"
            fontWeight="bold"
            fontSize="18px"
            color="#2c3b69"
            fontFamily="Handlee"
          >
            Paneer
          </Text>
        </Box>
        <Box
          data-aos="zoom-in-up"
          borderRadius="10"
          boxShadow="rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"
          _hover={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
        >
          <Image
            h={{ base: "45vh", md: "30vh", lg: "30vh" }}
            w="100%"
            objectFit="cover"
            src="https://www.vegrecipesofindia.com/wp-content/uploads/2012/05/lassi-recipe-3.jpg"
            alt="lassi"
            loading="lazy"
            borderTopRadius="10"
          />

          <Text
            m="4"
            fontWeight="bold"
            fontSize="18px"
            color="#2c3b69"
            fontFamily="Handlee"
          >
            Lassi
          </Text>
        </Box>
        <Box
          data-aos="zoom-in-up"
          borderRadius="10"
          boxShadow="rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"
          _hover={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
        >
          <Image
            h={{ base: "45vh", md: "30vh", lg: "30vh" }}
            w="100%"
            objectFit="cover"
            src="https://www.allrecipes.com/thmb/pH8hoFfytcOT9XVK1DSmxv3L0OU=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/140877-easy-eggless-strawberry-ice-cream-ddmfs-3x4-1-092e4d11b59049c8b3843014ea3c57f2.jpg"
            alt="icecream"
            loading="lazy"
            borderTopRadius="10"
          />

          <Text
            m="4"
            fontWeight="bold"
            fontSize="18px"
            color="#2c3b69"
            fontFamily="Handlee"
          >
            Icecream
          </Text>
        </Box>
        <Box
          data-aos="zoom-in-up"
          borderRadius="10"
          boxShadow="rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"
          _hover={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
        >
          <Image
            h={{ base: "45vh", md: "30vh", lg: "30vh" }}
            w="100%"
            objectFit="cover"
            src="https://assets.winni.in/product/primary/2023/4/84511.jpeg?dpr=1&w=500"
            alt="cake"
            loading="lazy"
            borderTopRadius="10"
          />

          <Text
            m="4"
            fontWeight="bold"
            fontSize="18px"
            color="#2c3b69"
            fontFamily="Handlee"
          >
            Cake
          </Text>
        </Box>

        <Box
          data-aos="zoom-in-up"
          borderRadius="10"
          boxShadow="rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"
          _hover={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
        >
          <Image
            h={{ base: "45vh", md: "30vh", lg: "30vh" }}
            w="100%"
            objectFit="cover"
            src="https://i.pinimg.com/originals/73/09/20/73092074e0675fe484233e9f2b3880f0.jpg"
            alt="chocolates"
            loading="lazy"
            borderTopRadius="10"
          />

          <Text
            m="4"
            fontWeight="bold"
            fontSize="18px"
            color="#2c3b69"
            fontFamily="Handlee"
          >
            Chocolates
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default Products;
