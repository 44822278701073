import { Box, Image, Text, Flex } from "@chakra-ui/react";
import React from "react";
import girl from "../assets/girl.png";

const Benefits = () => {
  return (
    <Box w="100%" mt={{ base: "20", md: "40" }} bg="#0135a6" pt="10">
      <Flex
        alignItems="center"
        w="90%"
        m="auto"
        gap={{ base: "10", md: "20" }}
        flexDirection={{ base: "column-reverse", md: "row" }}
      >
        <Box w="100%">
          <Image src={girl} alt="girl-with-glass" loading="lazy" />
        </Box>
        <Box w="100%">
          <Flex alignItems="center" gap="3">
            <Box color="#f2f2f2" fontSize="14px">
              #health
            </Box>
            <Box color="#f2f2f2" fontSize="14px">
              #nutrition
            </Box>
            <Box color="#f2f2f2" fontSize="14px">
              #protein
            </Box>
          </Flex>
          <Text
            fontSize={{ base: "25px", md: "30px" }}
            fontWeight="600"
            color="#f2f2f2"
            mt="2"
          >
            Milk is always good for health
          </Text>

          <Text color="#f2f2f2" mt="4">
            Milk is beneficial for health due to its nutritional value,
            including calcium, protein, vitamin D, and other essential
            nutrients. It promotes strong bones and teeth, aids in weight
            management, supports heart health, and contributes to hydration.
            Milk's nutrients also play a role in cognitive health and muscle
            recovery.
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default Benefits;
